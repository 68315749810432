import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import * as PropTypes from "prop-types";

import Layout from '../components/layout';

const propTypes = {
  data: PropTypes.object.isRequired,
};

class PressList extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const { currentPage, pagesCount } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === pagesCount;
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
    const nextPage = (currentPage + 1).toString();

    return (
      <Layout>
        <div className="skipScroll">
          <Helmet
            title={'temtum in the press | Read our press articles, featuring temtum and our team '}
            meta={[
              {
                name: 'description',
                content: 'The temtum Network has been independently tested by BSI, a Crest accredited agency, to verify claims on speed, scalability and security.'
              }
            ]}
          />
          <div>
            <div className="hd-space"/>
            <div className="page">
              <section className=" text-white skipScroll bg2X bg-research">
                <div className="darker video-overlay"/>
                <div className="wow3 video-overlay"/>
                <div className="container">
                  <div className="row" data-aos="fade-in" data-aos-duration="1200">
                    <div className="col col-12 col-sm-6 col-md-6">
                      <h3 className="display-2 font-weight-normal mb-3 headline red pb-3">temtum in the press</h3>
                      <p className="lead">
                        Read our press articles, featuring temtum and our team.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="skipScroll ">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 card-deckX plain-cards blog-list">
                      <div>
                        <h2 className="display-3 font-weight-bold mb-5 pb-3 headline red">
                          Press
                        </h2>
                        <div>
                          {
                            posts.map(({ node }) => (
                              <div key={node.id}>
                                <div className="card no-border mb-3">
                                  <div className="card-body">
                                    <h4 className="card-title font-weight-bolder">
                                      <Link to={node.fields.slug} className="card-title ">
                                        {node.frontmatter.title}
                                      </Link>
                                    </h4>
                                    <ul className="list-inline small mb-0">
                                      <li className="list-inline-item">
                                        <span className="card-text text-muted">in</span> <span className="upper text-blue font-weight-bold">{node.frontmatter.category}</span>
                                      </li>
                                      <li className="list-inline-item">
                                        <div className="card-text">
                                        <span className="text-muted">
                                          <i className="fa fa-clock mr-2"/>
                                          {node.frontmatter.date}
                                        </span>
                                        </div>
                                      </li>
                                    </ul>
                                    <div className="author-box-small author-box-small--justify-normal mt-3 pt-3 mb-0">
                                      <img
                                        src={require(`../img/${node.frontmatter.author_image}`)}
                                        alt="icon"
                                        width={44} height={44}
                                      />
                                      <div className="font-weight-bold nameX small">{node.frontmatter.author}</div>
                                      <span className="text-muted small">{node.frontmatter.author_sub}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>

                        <div className="text-center">
                          <div className="press-pagination">
                            {!isFirst ?
                              (
                                <li>
                                  <Link to={`/press/${prevPage}/`} rel="prev" className="press-pagination__navigation">
                                    <i className="fa fa-angle-left"/>
                                  </Link>
                                </li>
                              ) :
                              (
                                <li>
                                  <button type="button" className="press-pagination__navigation press-pagination__navigation--disabled">
                                    <i className="fa fa-angle-left"/>
                                  </button>
                                </li>
                              )
                            }
                            {Array.from({ length: pagesCount }, (_, i) => (
                              <li key={`page${i + 1}`}>
                                <Link to={'/press/' + (i === 0 ? '' : `${i + 1}/`)}
                                      className={[
                                        'press-pagination__item',
                                        i + 1 === currentPage ? 'press-pagination__item--active' : ''
                                      ].join(' ')}>
                                  {i + 1}
                                </Link>
                              </li>
                            ))}

                            {!isLast ?
                              (
                                <li>
                                  <Link to={`/press/${nextPage}/`} rel="next" className="press-pagination__navigation">
                                    <i className="fa fa-angle-right"/>
                                  </Link>
                                </li>
                              ) :
                              (
                                <li>
                                  <button type="button" className="press-pagination__navigation press-pagination__navigation--disabled">
                                    <i className="fa fa-angle-right"/>
                                  </button>
                                </li>
                              )
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-4">
                      <h3 className="display-4 font-weight-bold mb-5 pb-3 headline blue">
                        Latest News
                      </h3>
                      <ul className="latest-news-list-group">
                        {
                          posts.map(({ node }, i) => (
                            i > 0 && i < 5 &&
                            <li key={node.id} className="latest-news-item">
                              <div className="latest-news-item__text">
                                <Link to={node.fields.slug} className="latest-news-item__title">
                                  {node.frontmatter.title}
                                </Link>
                                <div className="latest-news-item__date">
                                <span className="text-muted">
                                  <i className="fa fa-clock mr-2"/>
                                  {node.frontmatter.date}
                                </span>
                                </div>
                              </div>
                              <div className="latest-news-item__image-container">
                                <div className="latest-news-item__image-wrapper">
                                  <Img
                                    fixed={node.frontmatter.cover_image.childImageSharp.fixed}
                                    className="latest-news-item__image"
                                    alt={node.frontmatter.title}
                                    title={node.frontmatter.title} />
                                </div>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

PressList.propTypes = propTypes;

export default PressList;

export const pressListQuery = graphql`
  query pressListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip 
      sort: {
        fields: [frontmatter___date], 
        order: DESC
      }, 
      filter: {
        frontmatter: {
          posttype: {regex: "/press/"}
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            category
            posttype
            author
            author_sub
            author_image
            cover_image {
              publicURL
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          excerpt
          wordCount {
            paragraphs
            sentences
            words
          }
        }
      }
    }
  }
`